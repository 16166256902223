import styled, { css } from 'styled-components';
import { Typography } from '@material-ui/core';
import { Swiper } from 'swiper/react';
import Caret from '../../assets/images/icon-caret.svg';

const Wrapper = styled.div`
  display: ${props => props.column ? 'block' : 'flex'};
  padding: ${props => props.column ? '0' : '0 15px'};
`;

const SplitGraph = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: ${props => props.large ? 'none' : '125px'};
  margin: 0;
  border-radius: 5px;
  border: 1px solid #EAEAEA;
  transition: border 200ms ease-out, background-color 200ms ease-out;

  &:before {
    content: '';
    display: block;
    padding-bottom: ${props => props.large ? '75%' : '40%'};
  }

  &:hover {
    border: 1px solid #1FC676;
    background-color: #FBFBFB;
    cursor: pointer;
  }

  ${props => props.active && css`
    border: 1px solid #1FC676;
    background-color: #FBFBFB;

    &:hover {
      cursor: auto;
    }
  `}
`;

const Graph = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 75%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const Areas = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
`;

const AreaItem = styled.div`
  margin: 0 15px 5px 0;
`;

const AreaTitle = styled(Typography)`
  display: inline-block;
  font-size: 14px;
  color: #626262;
`;

const AreaSize = styled(Typography)`
  font-size: 14px;
`;

const StyledTitle = styled(Typography)`
  font-size: 14px;
  margin-bottom: 5px;
`;

const SplitTitle = styled.div`
  margin: 5px 0 15px;
  font-size: 12px;
  padding: 0;
`;

const Selected = styled.div`
  padding: 0 16px;
`;

const StyledSwiper = styled(Swiper)`
  padding: 0 15px;

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    z-index: 2;
    top: calc(50% - 20px);
    transform: translateY(-50%);
    height: 30px;
    width: 30px;
    background-color: #FBFBFB;
    border-radius: 5px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: opacity 200ms ease-out;

    &.swiper-button-disabled {
      opacity: 0;
      pointer-events: none;
    }

    &:after {
      content: url(${Caret});
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    right: 10px;

    &:after {
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }
`;

export {
	Wrapper,
	SplitGraph,
	Graph,
	Areas,
	AreaTitle,
	AreaSize,
	AreaItem,
	StyledTitle,
	SplitTitle,
	Selected,
	StyledSwiper,
};
