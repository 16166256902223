import { useTheme, StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import FloorTypeAccordion from '../FloorTypeAccordion';
import './App.css';

import AtticIcon from '../../assets/images/icon-attic.svg';
import FullFloorsIcon from '../../assets/images/icon-full-floors.svg';
import FirstFloorIcon from '../../assets/images/icon-first-floor.svg';
import UndergroundIcon from '../../assets/images/icon-underground.svg';

function App() {
	const muiTheme = useTheme();

	return (
		<StylesProvider injectFirst>
			<SCThemeProvider theme={muiTheme}>
				<div className="App">
					<div className="sidebar">
						<FloorTypeAccordion title="Attic" icon={AtticIcon} />
						<FloorTypeAccordion title="Full floors" icon={FullFloorsIcon} />
						<FloorTypeAccordion title="First floor" icon={FirstFloorIcon} />
						<FloorTypeAccordion title="Underground" icon={UndergroundIcon} />
					</div>
				</div>
			</SCThemeProvider>
		</StylesProvider>
	);
}

export default App;
