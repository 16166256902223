import React, { useState } from 'react';
import FloorTypeRow from '../FloorTypeRow';
import FloorTypeOptions from '../FloorTypeOptions';
import { HNFEdit, HNFEditExtended } from '../HNFEdit';
import { NNFEdit, NNFEditExtended } from '../NNFEdit';
import { IncomeEdit, IncomeEditExtended } from '../IncomeEdit';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	AccordionImage,
	AccordionTitle,
	AccordionInfo,
	AccordionInfoItem,
	InfoItemTitle,
	InfoItemValue,
} from './style';
import CaretIcon from '../../assets/images/icon-caret.svg';

function FloorTypeAccordion(props) {
	const {title, icon} = props;
	const [isOpen, setIsOpen] = useState({hnfOpen: false, nnfOpen: false, incomeOpen: false});

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<img src={CaretIcon} alt="" role="presentation" />}
			>
				<AccordionImage src={icon} alt="" role="presentation" />
				<AccordionTitle component="h2">{title}</AccordionTitle>
				<AccordionInfo>
					<AccordionInfoItem>
						<InfoItemTitle>Strategy</InfoItemTitle>
						<InfoItemValue>Mixed</InfoItemValue>
					</AccordionInfoItem>
					<AccordionInfoItem>
						<InfoItemTitle>Usage</InfoItemTitle>
						<InfoItemValue>Mixed</InfoItemValue>
					</AccordionInfoItem>
					<AccordionInfoItem>
						<InfoItemTitle>Apartments</InfoItemTitle>
						<InfoItemValue>8</InfoItemValue>
					</AccordionInfoItem>
					<AccordionInfoItem>
						<InfoItemTitle>Avg Income</InfoItemTitle>
						<InfoItemValue>4 440 CHF/m²</InfoItemValue>
					</AccordionInfoItem>
				</AccordionInfo>
			</AccordionSummary>
			<AccordionDetails>
				<FloorTypeRow>
					<FloorTypeOptions />
				</FloorTypeRow>
				<FloorTypeRow
					title="Apartment mix - HNF"
					editText="Edit mix"
					editIsOpen={isOpen.hnfOpen}
					editHandler={() => setIsOpen({...isOpen, hnfOpen: !isOpen.hnfOpen})}
					noPadding
				>
					{isOpen.hnfOpen && (
						<HNFEditExtended />
					) || (
						<HNFEdit />
					)}
				</FloorTypeRow>
				<FloorTypeRow
					title="Apartment mix - NNF"
					editText="Edit use"
					editIsOpen={isOpen.nnfOpen}
					editHandler={() => setIsOpen({...isOpen, nnfOpen: !isOpen.nnfOpen})}
				>
					{isOpen.nnfOpen && (
						<NNFEditExtended />
					) || (
						<NNFEdit />
					)}
				</FloorTypeRow>
				<FloorTypeRow
					title="Income"
					editText="Edit income"
					editIsOpen={isOpen.incomeOpen}
					editHandler={() => setIsOpen({...isOpen, incomeOpen: !isOpen.incomeOpen})}
				>
					{isOpen.incomeOpen && (
						<IncomeEditExtended />
					) || (
						<IncomeEdit />
					)}
				</FloorTypeRow>
			</AccordionDetails>
		</Accordion>
	);
}

export default FloorTypeAccordion;
