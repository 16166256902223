import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { Income, IncomeCell, IncomeText } from './style';
import { StyledInput, StyledInputAdornment } from '../StyledInput';
import ColorDot from '../ColorDot';

function IncomeEditExtended() {
	return (
		<>
			<Income container spacing={1} justify="flex-start">
				<IncomeCell item xs={12}>
					<div>
						<ColorDot color="#263A96" large />
						<IncomeText component="span">Studio 100%</IncomeText>
					</div>
				</IncomeCell>
			</Income>
			<Income container spacing={1} justify="flex-start">
				<IncomeCell item xs={7} sm={3}>
					<IncomeText component="span" black>Apartment #31</IncomeText>
				</IncomeCell>
				<Hidden only="xs">
					<IncomeCell item xs={6} sm={3}>
						<IncomeText component="span">Corner</IncomeText>
					</IncomeCell>
					<IncomeCell item xs={6} sm={3}>
						<IncomeText component="span">No balcony</IncomeText>
					</IncomeCell>
				</Hidden>
				<IncomeCell item xs={5} sm={3}>
					<StyledInput
						id="price-input"
						value="6800"
						endAdornment={<StyledInputAdornment
							position="end">CHF/m²</StyledInputAdornment>}
						aria-describedby="price-input-helper-text"
						inputProps={{
							'aria-label': 'price',
						}}
					/>
				</IncomeCell>
				<Hidden smUp>
					<IncomeCell item xs={12}>
						<Box mr={3}>
							<IncomeText component="span">Corner</IncomeText>
						</Box>
						<Box mr={3}>
							<IncomeText component="span">No balcony</IncomeText>
						</Box>
					</IncomeCell>
				</Hidden>
			</Income>
		</>
	);
}

function IncomeEdit() {
	return (
		<Income container spacing={1} justify="flex-start">
			<IncomeCell item xs={6} sm={4}>
				<div>
					<ColorDot color="#263A96" large />
					<IncomeText component="span">Studio 100%</IncomeText>
				</div>
			</IncomeCell>
			<IncomeCell item xs={6} sm={4} mobileLast>
				<IncomeText>4 apartments</IncomeText>
			</IncomeCell>
			<IncomeCell item xs={6} sm={4}>
				<IncomeText align="right">Avg price - 6 800 CHF/m²</IncomeText>
			</IncomeCell>
		</Income>
	);
}

export { IncomeEdit, IncomeEditExtended };
