import React from 'react';
import { Grid } from '@material-ui/core';
import {
	Checkbox,
	CheckboxDescription,
	CheckboxIcon,
	CheckboxLabel,
	CheckboxText,
	StyledCheckboxLabel
} from '../Checkbox';
import { StyledInput, StyledInputAdornment } from '../StyledInput';

import BuildingServicesIcon from '../../assets/images/icon-building-services.svg';
import LaundryUnitIcon from '../../assets/images/icon-laundry-unit.svg';
import StorageUnitIcon from '../../assets/images/icon-storage-unit.svg';

function NNFEditExtended() {
	return (
		<Grid container spacing={1}>
			<Grid container item xs={12}>
				<Grid item xs={9}>
					<StyledCheckboxLabel
						control={<Checkbox />}
						label={
							<CheckboxLabel>
								<CheckboxIcon src={BuildingServicesIcon} title="Building services area"
											  alt="Building services area" />
								<CheckboxText component="span">Building services area</CheckboxText>
							</CheckboxLabel>
						}
					/>
				</Grid>
				<Grid
					item
					container
					xs={3}
					direction="row"
					justify="center"
					alignItems="center"
				>
					<StyledInput
						id="services-input"
						value="40"
						endAdornment={<StyledInputAdornment
							position="end">m²</StyledInputAdornment>}
						aria-describedby="price-input-helper-text"
						inputProps={{
							'aria-label': 'Building services area',
						}}
					/>
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				<Grid item xs={9}>
					<StyledCheckboxLabel
						control={<Checkbox />}
						label={
							<CheckboxLabel>
								<CheckboxIcon src={LaundryUnitIcon} title="Laundry units per apt."
											  alt="Laundry units per apt." />
								<CheckboxText component="span">Laundry units per apt.</CheckboxText>
							</CheckboxLabel>
						}
					/>
				</Grid>
				<Grid
					item
					container
					xs={3}
					direction="row"
					justify="center"
					alignItems="center"
				>
					<StyledInput
						id="services-input"
						value="1"
						inputProps={{
							'aria-label': 'Laundry units per apt.',
						}}
					/>
				</Grid>
			</Grid>
			<Grid container item xs={12}>
				<Grid item xs={9}>
					<StyledCheckboxLabel
						control={<Checkbox />}
						label={
							<CheckboxLabel>
								<CheckboxIcon src={StorageUnitIcon} title="Storage units per apt."
											  alt="Storage units per apt." />
								<CheckboxText component="span">Storage units per apt.</CheckboxText>
							</CheckboxLabel>
						}
					/>
				</Grid>
				<Grid
					item
					container
					xs={3}
					direction="row"
					justify="center"
					alignItems="center"
				>
					<StyledInput
						id="storage-units-input"
						value="3"
						inputProps={{
							'aria-label': 'Storage units per apt.',
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}

function NNFEdit() {
	return (
		<Grid container spacing={3}>
			<Grid item xs={4} md={3}>
				<StyledCheckboxLabel
					control={<Checkbox />}
					label={
						<CheckboxLabel>
							<CheckboxIcon src={BuildingServicesIcon}
										  title="Building services area"
										  alt="Building services area" />
						</CheckboxLabel>
					}
				/>
				<CheckboxDescription>1 per Apt.</CheckboxDescription>
			</Grid>
			<Grid item xs={4} md={3}>
				<StyledCheckboxLabel
					control={<Checkbox />}
					label={
						<CheckboxLabel>
							<CheckboxIcon src={LaundryUnitIcon}
										  title="Laundry units per apt."
										  alt="Laundry units per apt." />
						</CheckboxLabel>}
				/>
				<CheckboxDescription>40 m² per Apt.</CheckboxDescription>
			</Grid>
			<Grid item xs={4} md={3}>
				<StyledCheckboxLabel
					control={<Checkbox />}
					label={
						<CheckboxLabel>
							<CheckboxIcon src={StorageUnitIcon}
										  title="Storage units per apt."
										  alt="Storage units per apt." />
						</CheckboxLabel>}
				/>
				<CheckboxDescription>1 per Apt.</CheckboxDescription>
			</Grid>
		</Grid>
	);
}

export { NNFEdit, NNFEditExtended };
