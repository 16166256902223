import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

const IncomeText = styled(Typography)`
  font-size: 14px;
  color: ${(props) => props.black ? '#030B0E' : '#626262'};
`;

const Income = styled(Grid)`
  margin: 5px 0;
`;

const IncomeCell = styled(Grid)`
  display: flex;
  align-items: center;

  ${(props) => props.mobileLast && css`
    ${({theme}) => `
    	order: 1;
    
		${theme.breakpoints.up('sm')} {
			order: 0;
		}
	`}
  `}

`;

export { Income, IncomeCell, IncomeText };
