import styled from 'styled-components';

const ColorDot = styled.span`
  display: inline-block;
  width: ${props => props.large ? '13px' : '10px'};
  height: ${props => props.large ? '13px' : '10px'};
  border-radius: 50%;
  margin: ${props => props.large ? '0 10px -1px 0' : '0 10px 0 0'};
  background-color: ${props => props.color || '#000'};
`;

export { ColorDot };
