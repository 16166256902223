import React from 'react';
import { Content, Header, HeaderTitle, Wrapper, EditButton, CaretImg } from './style.js';
import CaretIcon from '../../assets/images/icon-caret.svg';

const Caret = ({editIsOpen}) => (
	<CaretImg src={CaretIcon} alt="" editIsOpen={editIsOpen} role="presentation" />
);

function FloorTypeRow(props) {
	const { title, editText, children, editHandler, noPadding, editIsOpen } = props;

	return (
		<Wrapper>
			{title && (
				<Header>
					<HeaderTitle component="h3">{title}</HeaderTitle>
					<EditButton size="small" endIcon={<Caret editIsOpen={editIsOpen} />} onClick={editHandler}>
						{ !editIsOpen ? (editText ? editText : 'Edit') : 'Show less' }
					</EditButton>
				</Header>
			)}
			{children && (
				<Content noPadding={noPadding}>
					{children}
				</Content>
			)}
		</Wrapper>
	);
}

export default FloorTypeRow;
