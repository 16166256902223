import { withStyles } from '@material-ui/core/styles';
import { InputAdornment, InputBase } from '@material-ui/core';
import styled  from 'styled-components';

const StyledInput = withStyles(() => ({
	root: {
		border: '1px solid #ced4da',
		backgroundColor: '#FBFBFB',
		borderRadius: 5,
		padding: '2px 5px 0',
	},
	input: {
		position: 'relative',
		border: 'none',
		fontSize: 16,
		padding: 5,
	},
}))(InputBase);

const StyledInputAdornment = styled(InputAdornment)`
  p {
    font-size: 10px;
  }
`;

export { StyledInput, StyledInputAdornment };
