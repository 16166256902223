import styled  from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';

const AccordionImage = styled.img`
  margin-right: 15px;
`;

const AccordionTitle = styled(Typography)`
  margin-right: 15px;
`;

const AccordionInfo = styled.div`
  ${({theme}) => `
	  display: none;
	  justify-content: flex-end;
	  position: absolute;
	  top: 50%;
	  right: 30px;
	  height: 100%;
	  transform: translateY(-50%);
	  opacity: 0;
	  transition: opacity 200ms ease-out;
	  
	  ${theme.breakpoints.up('sm')} {
		display: flex;
	  }
	
	  &:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		right: 0;
		width: calc(100% + 15px);
		height: 100%;
		background: #F5F5F5;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(245, 245, 245, 1) 5%, rgba(245, 245, 245, 1) 100%);
	  }
  `}
`;

const AccordionInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
`;

const InfoItemTitle = styled(Typography)`
  color: #626262;
  font-size: 12px;
`;

const InfoItemValue = styled(Typography)`
  font-size: 12px;
`;

const StyledAccordionSummary = styled(MuiAccordionSummary)`
  position: relative;

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
  }

  &:hover {
    ${AccordionInfo} {
      opacity: 1;
    }
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: 8px 0 16px;
`;

const StyledAccordion = styled(MuiAccordion)`
  margin: 0 0 10px;
  box-shadow: none;
  border-radius: 2px;
  border: 1px solid #EAEAEA;
  background-color: #F5F5F5;
  transition: box-shadow 200ms ease-out;

  &:before {
    content: none;
  }

  &:hover {
    box-shadow: 0 2px 5px rgba(5, 15, 30, 0.15);
  }

  &.Mui-expanded {
    ${AccordionInfo} {
      opacity: 0;
    }
  }
`;

const Accordion = withStyles({
	root: {
		boxShadow: 'none',
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto auto 10px',
		},
	},
	expanded: {},
})(StyledAccordion);

const AccordionSummary = withStyles({
	root: {
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(StyledAccordionSummary);

export {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	AccordionImage,
	AccordionTitle,
	AccordionInfo,
	AccordionInfoItem,
	InfoItemTitle,
	InfoItemValue,
};
