import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import {
	Wrapper,
	SplitGraph,
	Graph,
	Areas,
	AreaItem,
	AreaTitle,
	AreaSize,
	StyledTitle,
	SplitTitle,
	Selected,
	StyledSwiper,
} from './style';
import ColorDot from '../ColorDot';

import 'swiper/swiper.min.css';

import StudioSplitImage from '../../assets/images/image-studios-split.svg';
import StudioPlusSplitImage from '../../assets/images/image-studio-plus-split.svg';
import FamilySplitImage from '../../assets/images/image-family-apartments-split.svg';
import CooperativeSocietySplitImage from '../../assets/images/image-cooperative-society-split.svg';

const Area = ({title, percentage, size}) => (
	<AreaItem m={3}>
		<div>
			<ColorDot color="#263A96" />
			<AreaTitle component="span">{title} {percentage && `${percentage}%`}</AreaTitle>
		</div>
		<AreaSize>{size} m²</AreaSize>
	</AreaItem>
);

SwiperCore.use([Navigation]);

function HNFEditExtended() {
	return (
		<Wrapper column>
			<StyledSwiper
				navigation
				spaceBetween={30}
				slidesPerView={3}
			>
				<SwiperSlide>
					<SplitGraph active large>
						<Graph style={{backgroundImage: `url(${StudioSplitImage})`}} />
					</SplitGraph>
					<SplitTitle>Studios</SplitTitle>
				</SwiperSlide>
				<SwiperSlide>
					<SplitGraph large>
						<Graph style={{backgroundImage: `url(${StudioPlusSplitImage})`}} />
					</SplitGraph>
					<SplitTitle>Studio Plus</SplitTitle>
				</SwiperSlide>
				<SwiperSlide>
					<SplitGraph large>
						<Graph style={{backgroundImage: `url(${CooperativeSocietySplitImage})`}} />
					</SplitGraph>
					<SplitTitle>Cooperative society</SplitTitle>
				</SwiperSlide>
				<SwiperSlide>
					<SplitGraph large>
						<Graph style={{backgroundImage: `url(${FamilySplitImage})`}} />
					</SplitGraph>
					<SplitTitle>Family apartments</SplitTitle>
				</SwiperSlide>
			</StyledSwiper>
			<Selected>
				<StyledTitle>Selected split</StyledTitle>
				<Areas>
					<Area title="Studio" percentage="100" size="30-40" />
				</Areas>
			</Selected>
		</Wrapper>
	);
}

function HNFEdit() {
	return (
		<Wrapper>
			<SplitGraph active>
				<Graph style={{backgroundImage: `url(${StudioSplitImage})`}} />
			</SplitGraph>
			<Selected>
				<StyledTitle>Selected split</StyledTitle>
				<Areas>
					<Area title="Studio" percentage="100" size="30-40" />
				</Areas>
			</Selected>
		</Wrapper>
	);
}

export { HNFEdit, HNFEditExtended }
