import { withStyles } from '@material-ui/core/styles';
import MuiCheckbox from '@material-ui/core/Checkbox';
import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel } from '@material-ui/core';

const StyledCheckbox = withStyles({
	root: {
		'&$checked': {
			color: '#1FC676',
		},
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	checked: {},
})((props) => <MuiCheckbox color="default" {...props} />);

const Checkbox = styled(StyledCheckbox)`
  ${(props) => props.noPadding && css`
    padding: 0;
  `}
`;

const CheckboxDescription = styled(Typography)`
  font-size: 12px;
`;

const CheckboxIcon = styled.img`
  margin: 0 10px 0 0;
  width: 25px;
`;

const CheckboxText = styled(Typography)`
  font-size: 12px;
  margin-left: 5px;
`;

const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckboxLabel = styled(FormControlLabel)`
  ${(props) => props.bordered && css`
    border: 1px solid #C9CED0;
    border-radius: 50px;
	margin: 0;
	padding: 5px 15px 5px 10px;
  `}
`;

export { Checkbox, CheckboxDescription, CheckboxIcon, CheckboxText, CheckboxLabel, StyledCheckboxLabel };
