import styled from 'styled-components';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 0 30px;

  &:last-child {
    margin: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 10px;
`;

const HeaderTitle = styled(Typography)`
  color: #4A5659;
  font-weight: 500;
`;

const Content = styled.div`
  padding: ${(props) => props.noPadding ? '0' : '0 16px'};
`;

const EditButton = styled(Button)`
  color: #4A5659;
  text-transform: none;
`;

const CaretImg = styled.img`
  transition: transform 125ms ease-out;
  transform: ${(props) => props.editIsOpen ? 'rotate(180deg)' : 'none'}
`;

export { Wrapper, Header, HeaderTitle, Content, EditButton, CaretImg };
