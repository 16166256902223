import React from 'react';
import { Grid } from '@material-ui/core';
import { Checkbox, CheckboxText, StyledCheckboxLabel } from '../Checkbox';

function FloorTypeOptions() {
	return (
		<Grid container>
			<Grid cell xs="4" sm="3">
				<StyledCheckboxLabel
					bordered
					control={<Checkbox disableRipple noPadding />}
					label={<CheckboxText>HNF</CheckboxText>}
				/>
			</Grid>
			<Grid cell xs="4" sm="3">
				<StyledCheckboxLabel
					bordered
					control={<Checkbox disableRipple noPadding />}
					label={<CheckboxText>NNF</CheckboxText>}
				/>
			</Grid>
		</Grid>
	);
}

export default FloorTypeOptions;
